var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-list"},[_c('a',{ref:"download",staticStyle:{"display":"none"}}),_c('ProjectMenu'),_c('div',{staticClass:"wrap"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"项目名称"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入项目名称","clearable":""},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}})],1),_c('el-form-item',{attrs:{"label":"项目编号"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入项目编号","clearable":""},model:{value:(_vm.projectId),callback:function ($$v) {_vm.projectId=$$v},expression:"projectId"}})],1),_c('el-form-item',{attrs:{"label":"项目所属地区"}},[_c('el-cascader',{attrs:{"size":"small","options":_vm.regionData,"props":{
            expandTrigger: 'click',
            checkStrictly: true,
            value: 'name',
            label: 'name',
          },"clearable":""},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"small","icon":"el-icon-search"},on:{"click":_vm.getProjectList}},[_vm._v("查询")])],1)],1)],1),_c('div',{staticClass:"wrap-blank"},[_c('el-button',{attrs:{"size":"small","plain":"","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$router.push({
          name: 'ProjectInfo',
          query: {
            action: 'add',
            id: null,
          },
        })}}},[_vm._v("添加")]),_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"small","plain":""},on:{"click":_vm.delProject}},[_vm._v("删除")]),_c('el-button',{attrs:{"type":"success","size":"small","plain":"","icon":"el-icon-download"},on:{"click":function($event){return _vm.exportProject('currentPage')}}},[_vm._v("导出当前数据")]),_c('el-button',{attrs:{"type":"success","size":"small","plain":"","icon":"el-icon-download"},on:{"click":function($event){return _vm.exportProject('all')}}},[_vm._v("导出全部数据")])],1),_c('div',{staticClass:"wrap"},[_vm._l((_vm.projectList),function(item,index){return _c('div',{key:index,staticClass:"project-item"},[_c('el-radio',{attrs:{"label":index},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(_vm._s(""))]),_c('el-descriptions',[_c('template',{slot:"title"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'ProjectInfo',
                query: {
                  action: 'get',
                  id: item.id,
                },
              })}}},[_vm._v(_vm._s(item.proName))])]),_c('el-descriptions-item',{attrs:{"label":"项目编号"}},[_vm._v(_vm._s(item.projectSysNo))]),_c('el-descriptions-item',{attrs:{"label":"项目负责人"}},[_vm._v(_vm._s(item.proMaster))]),_c('el-descriptions-item',{attrs:{"label":"负责人电话"}},[_vm._v(_vm._s(item.masterMobile))]),_c('el-descriptions-item',{attrs:{"label":"所属地区"}},[_vm._v(_vm._s(item.arearId))]),_c('el-descriptions-item',{attrs:{"label":"项目经理"}},[_vm._v(_vm._s(item.proManager))]),_c('el-descriptions-item',{attrs:{"label":"经理电话"}},[_vm._v(_vm._s(item.proManMobile))]),_c('el-descriptions-item',{attrs:{"label":"项目状态"}},[(item.proStatus == 1)?_c('span',[_vm._v("待开工")]):_vm._e(),(item.proStatus == 2)?_c('span',[_vm._v("进行中")]):_vm._e(),(item.proStatus == 3)?_c('span',[_vm._v("已完结")]):_vm._e()]),_c('el-descriptions-item',{attrs:{"label":"开始日期"}},[_vm._v(_vm._s(item.proStartTime))]),_c('el-descriptions-item',{attrs:{"label":"结束日期"}},[_vm._v(_vm._s(item.proEndTime))])],2)],1)}),_c('el-pagination',{attrs:{"layout":"total, sizes, prev, pager, next","page-size":_vm.pageSize,"current-page":_vm.pageNum,"page-sizes":[2, 5, 10, 20, 50],"total":_vm.totals},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"update:currentPage":function($event){_vm.pageNum=$event},"update:current-page":function($event){_vm.pageNum=$event},"current-change":_vm.getProjectList,"size-change":_vm.getProjectList}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }