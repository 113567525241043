<template>
  <div class="project-menu">
    <div class="main-menu">
      <router-link
        v-if="menuPermission.Project.ProjectList"
        :to="{ name: 'ProjectList' }"
        :class="[
          $route.name == 'ProjectList' ? 'child-menu-active' : '',
        ]"
      >项目管理</router-link>
      <router-link
        :to="{ name: 'ProjectFile' }"
        :class="[
          $route.name == 'ProjectFile' ? 'child-menu-active' : '',
        ]"
      >项目文档库</router-link>
      <router-link
        :to="{ name: 'ProjectQrcode' }"
        :class="[
          $route.name == 'ProjectQrcode' ? 'child-menu-active' : '',
        ]"
      >公示二维码</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectMenu',
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
}
</script>

<style lang="less" scoped>
@link-color: #2c85d8;

.project-menu {
  width: 1200px;
  margin: 0 auto;
  a {
    display: inline-block;
    padding: 10px 20px;
  }
}
</style>
