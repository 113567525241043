<template>
  <div class="project-list">
    <a ref="download" style="display: none" />
    <ProjectMenu />
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-input
            size="small"
            v-model="projectName"
            placeholder="请输入项目名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="项目编号">
          <el-input
            size="small"
            v-model="projectId"
            placeholder="请输入项目编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="项目所属地区">
          <el-cascader
            size="small"
            v-model="region"
            :options="regionData"
            :props="{
              expandTrigger: 'click',
              checkStrictly: true,
              value: 'name',
              label: 'name',
            }"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button size="small" icon="el-icon-search" @click="getProjectList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="
          $router.push({
            name: 'ProjectInfo',
            query: {
              action: 'add',
              id: null,
            },
          })
        "
        >添加</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        plain
        @click="delProject"
        >删除</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportProject('currentPage')"
        >导出当前数据</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        @click="exportProject('all')"
        >导出全部数据</el-button
      >
    </div>

    <div class="wrap">
      <div
        class="project-item"
        v-for="(item, index) in projectList"
        :key="index"
      >
        <el-radio v-model="radio" :label="index">{{ "" }}</el-radio>
        <el-descriptions>
          <template slot="title">
            <a
              @click="
                $router.push({
                  name: 'ProjectInfo',
                  query: {
                    action: 'get',
                    id: item.id,
                  },
                })
              "
              >{{ item.proName }}</a
            >
          </template>
          <el-descriptions-item label="项目编号">{{
            item.projectSysNo
          }}</el-descriptions-item>
          <el-descriptions-item label="项目负责人">{{
            item.proMaster
          }}</el-descriptions-item>
          <el-descriptions-item label="负责人电话">{{
            item.masterMobile
          }}</el-descriptions-item>
          <el-descriptions-item label="所属地区">{{
            item.arearId
          }}</el-descriptions-item>
          <el-descriptions-item label="项目经理">{{
            item.proManager
          }}</el-descriptions-item>
          <el-descriptions-item label="经理电话">{{
            item.proManMobile
          }}</el-descriptions-item>
          <el-descriptions-item label="项目状态">
            <span v-if="item.proStatus == 1">待开工</span>
            <span v-if="item.proStatus == 2">进行中</span>
            <span v-if="item.proStatus == 3">已完结</span>
          </el-descriptions-item>
          <el-descriptions-item label="开始日期">{{
            item.proStartTime
          }}</el-descriptions-item>
          <el-descriptions-item label="结束日期">{{
            item.proEndTime
          }}</el-descriptions-item>
        </el-descriptions>
      </div>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getProjectList"
        @size-change="getProjectList"
      >
      </el-pagination>
    </div>
  </div>
</template>

<style lang="less" scope>
.project-list {
  .el-form-item {
    margin-bottom: 0;
  }
  .project-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>


<script>
import ProjectMenu from "@/components/ProjectMenu.vue";

export default {
  name: "ProjectList",
  components: {
    ProjectMenu,
  },
  data() {
    return {
      loading: null,

      projectName: "",
      projectId: "",
      region: [],
      regionData: [],

      radio: null,

      projectList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,
    };
  },
  mounted() {
    this.getRegionData(); // 加载省市区数据
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {},
  methods: {
    // 加载省市区数据
    getRegionData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "get",
          url: "/data/region.json",
        })
        .then((res) => {
          console.log(res);
          that.regionData = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: that.projectName,
        projectSysNo: that.projectId,
        proAddress: that.region.join(""),
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log(res);
          that.projectList = res.data.projectList;
          that.totals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除项目
    delProject: function () {
      var that = this;
      if (that.radio == null) {
        that.$alert("请先选择要删除的项目", "提示", { showClose: false });
        return;
      }
      var project = that.projectList[that.radio];
      that
        .$confirm(`此操作将删除 ${project.proName}, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var data = {
            id: that.projectList[that.radio].id,
          };
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/project/projectDelete",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.loading.close();
              console.log("成功=>", res);
              if (res.code == 0) {
                that.$notify({
                  title: "操作成功",
                  message: "已成功删除了",
                  type: "success",
                });
                that.getProjectList();
              } else {
                that.$notify({
                  title: "警告",
                  message: res.data.msg,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log("失败=>", err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$notify({
            title: "警告",
            message: "已取消删除，请谨慎操作。",
            type: "warning",
          });
        });
    },
    // 导出项目
    exportProject: function (mode) {
      var that = this;
      var timestamp = new Date().getTime();
      if (mode == "currentPage") {
        var data = {
          proName: that.projectName,
          projectSysNo: that.projectId,
          proAddress: that.region.join(""),
          pageNum: that.pageNum,
          pageSize: that.pageSize,
        };
      } else if (mode == "all") {
        var data = {
          proName: "",
          projectSysNo: "",
          proAddress: "",
          pageNum: 1,
          pageSize: "",
        };
      } else {
        return;
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "post",
          url: "api/admin/project/projectExport",
          data: data,
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          transformRequest: [
            function (data) {
              let ret = "";
              for (let it in data) {
                ret +=
                  encodeURIComponent(it) +
                  "=" +
                  encodeURIComponent(data[it]) +
                  "&";
              }
              return ret;
            },
          ],
        })
        .then((res) => {
          console.log("exportProject", res);
          console.log("");
          const blob = res.data;
          that.$refs.download.href = URL.createObjectURL(blob);
          that.$refs.download.download = `项目列表${timestamp}.xls`;
          that.$refs.download.click();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
  },
};
</script>